import { parse as parseFns, format as formatFns } from "date-fns";
import { es, enUS } from "date-fns/locale";

export function parse(dateStr: string, format: string): Date {
  return parseFns(dateStr, format, new Date());
}

export function format(
  date: Date,
  format: string,
  options?: { locale: "en" | "es" }
): string {
  const locale = options?.locale === "en" ? enUS : es;
  return formatFns(date, format, { locale });
}
