import { Button, Input, Switch } from "@material-tailwind/react";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { createObituary } from "../../modules/obituaries/application/createObituary.useCase";
import { updateObituary } from "../../modules/obituaries/application/updateObituary.useCase";
import { Obituary } from "../../modules/obituaries/domain/Obituary";
import { ObituaryRepositorySupabase } from "../../modules/obituaries/infrastructure/ObituaryRepository.supabase";
import { useProperty } from "../shared/hooks/useProperty";
import { DatePicker } from "../shared/ui/DatePicker";
import { DropUpload } from "../shared/ui/DropUpload";
import { Field } from "../shared/ui/Field";
import toast from "react-hot-toast";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface FormData {
  profileImage: File | string;
  obituaryImage: File | string;
  name: string;
  age: number;
  notificationEmails: string;
  deceasedDate: Date;
  deceasedPlace: string;
  active: boolean;
}

const obituaryRepository = new ObituaryRepositorySupabase();

export function ObituaryEditPage() {
  const [obituaryImage, setObituaryImage] = useState<File>();
  const [profileImage, setProfileImage] = useState<File>();
  const [submitting, setSubmitting] = useState(false);

  const { ticker, obituaryId } = useParams() as {
    ticker: string;
    obituaryId?: string;
  };
  const navigate = useNavigate();
  const { property } = useProperty(ticker);

  const {
    data: obituary,
    mutate,
    error,
  } = useSWR(obituaryId ? `obituaries/${obituaryId}` : null, () =>
    obituaryRepository.findById(obituaryId as string)
  );

  const profileImageUrl = useMemo(() => {
    if (profileImage) {
      return URL.createObjectURL(profileImage);
    }
    return obituary?.profileImage;
  }, [obituary?.profileImage, profileImage]);

  const obituaryImageUrl = useMemo(() => {
    if (obituaryImage) {
      return URL.createObjectURL(obituaryImage);
    }
    return obituary?.obituaryImage;
  }, [obituary?.obituaryImage, obituaryImage]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      age: 80,
      notificationEmails: property?.email,
      deceasedDate: new Date(),
      deceasedPlace: "",
    },
    values: obituary
      ? {
          ...obituary,
          notificationEmails: obituary.notificationEmails.join(","),
        }
      : undefined,
  });

  const onSubmit = useCallback(
    async (event: FormData) => {
      if (!property) return;

      const notificationEmails = event.notificationEmails
        .split(",")
        .map((e) => e.trim());

      if (!obituary) {
        if (!profileImage || !obituaryImage) {
          console.error("No profile image or obituary image");
          return;
        }

        const obituary = Obituary.create({
          ...event,
          notificationEmails,
        });

        setSubmitting(true);
        const createdObituary = await createObituary(property, obituary, {
          profileImage: profileImage,
          obituaryImage: obituaryImage,
        }).finally(() => setSubmitting(false));

        toast.success("Esquela creada");

        mutate(createdObituary);
        setObituaryImage(undefined);
        setProfileImage(undefined);
        navigate(`/home/${property.ticker}/obituaries/${createdObituary.id}`, {
          replace: true,
        });
      } else {
        setSubmitting(true);
        const updatedObituary = await updateObituary(
          property,
          obituary.clone({
            name: event.name,
            age: event.age,
            deceasedDate: event.deceasedDate,
            deceasedPlace: event.deceasedPlace,
            active: event.active,
            notificationEmails,
          }),
          {
            profileImage: profileImage,
            obituaryImage: obituaryImage,
          }
        ).finally(() => setSubmitting(false));

        toast.success("Esquela actualizada");

        mutate(updatedObituary);
        setObituaryImage(undefined);
        setProfileImage(undefined);
      }
    },
    [property, profileImage, obituaryImage, obituary, mutate, navigate]
  );

  if (error) {
    console.error(`Error getting obituary ${obituaryId}`, error);
    throw error;
  }

  return (
    <div className="w-fit m-auto">
      <form
        className="flex flex-col gap-4 max-w-4xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex flex-col gap-4">
            <Field error={errors.profileImage?.message}>
              <Controller
                name="profileImage"
                control={control}
                rules={{ required: "La imagen es obligatoria" }}
                render={({ field: { onChange, ...field } }) => (
                  <DropUpload
                    {...field}
                    className="w-32 h-32 !p-3"
                    label="Imagen fallecido"
                    error={Boolean(errors.profileImage)}
                    onDrop={(files) => {
                      setProfileImage(files[0]);
                      onChange(files[0]);
                    }}
                  >
                    {profileImageUrl && (
                      <img
                        className="group-hover:opacity-50 object-cover w-full h-full"
                        src={profileImageUrl}
                        alt="deceased person image"
                      />
                    )}
                  </DropUpload>
                )}
              />
            </Field>
            <Field error={errors.name?.message}>
              <Input
                label="Nombre"
                type="text"
                error={Boolean(errors.name)}
                {...register("name", { required: "El nombre es obligatorio" })}
              />
            </Field>
            <Field error={errors.deceasedPlace?.message}>
              <Input
                label="Lugar de defunción"
                type="text"
                error={Boolean(errors.deceasedPlace)}
                {...register("deceasedPlace", {
                  required: "El lugar de defunción es obligatorio",
                })}
              />
            </Field>
            <Field error={errors.deceasedDate?.message}>
              <Controller
                name="deceasedDate"
                control={control}
                rules={{ required: "La fecha de defunción es obligatoria" }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Fecha de defunción"
                    error={Boolean(errors.deceasedDate)}
                  />
                )}
              />
            </Field>
            <Field error={errors.age?.message}>
              <Input
                label="Edad"
                type="number"
                inputMode="numeric"
                error={Boolean(errors.age)}
                {...register("age", { required: "La edad es obligatoria" })}
              />
            </Field>
            <Field
              className="flex flex-col gap-2"
              error={errors.notificationEmails?.message}
            >
              <Input
                label="Emails para pésames"
                error={Boolean(errors.notificationEmails)}
                {...register("notificationEmails", {
                  validate: (value) =>
                    !validateEmails(value)
                      ? "Los emails deben estar separados por coma"
                      : undefined,
                })}
              />
              <span className="text-sm italic text-gray-500">
                <InformationCircleIcon className="h-5 w-5 inline mr-1" />
                Introduzca tantos emails como quiera que sean notificados cada
                vez que se publique un nuevo pésame. Separe los emails con coma
                en el caso de que haya varios.
              </span>
            </Field>
            <Switch label="Activa" {...register("active")} />
          </div>
          <Field error={errors.obituaryImage?.message}>
            <Controller
              name="obituaryImage"
              control={control}
              rules={{ required: "La esquela es obligatoria" }}
              render={({ field: { onChange, ...field } }) => (
                <DropUpload
                  {...field}
                  label="Esquela"
                  error={Boolean(errors.obituaryImage)}
                  onDrop={(files) => {
                    setObituaryImage(files[0]);
                    onChange(files[0]);
                  }}
                >
                  {obituaryImageUrl && (
                    <img src={obituaryImageUrl} alt="obituary" />
                  )}
                </DropUpload>
              )}
            />
          </Field>
        </div>
        <Button type="submit" loading={submitting} className="justify-center">
          {obituary?.id ? "Actualizar" : "Crear esquela"}
        </Button>
      </form>
    </div>
  );
}

function validateEmails(value: string) {
  if (!value) return true;

  return value
    .split(",")
    .every((email) =>
      /^[a-zA-Z0-9]+([._-][0-9a-zA-Z]+)*@[a-zA-Z0-9]+([.-][0-9a-zA-Z]+)*\.[a-zA-Z]{2,}$/.test(
        email.trim()
      )
    );
}
