export interface PropertyProps {
  id: string;
  ticker: string;
  name: string;
  url: string;
  logo: string;
  favicon: string;
  address: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phone: string;
}

export class Property {
  private constructor(
    public readonly id: string,
    public readonly ticker: string,
    public readonly name: string,
    public readonly url: string,
    public readonly logo: string,
    public readonly favicon: string,
    public readonly address: string,
    public readonly city: string,
    public readonly state: string,
    public readonly country: string,
    public readonly email: string,
    public readonly phone: string
  ) {}

  static of(props: PropertyProps): Property {
    return new Property(
      props.id,
      props.ticker,
      props.name,
      props.url,
      props.logo,
      props.favicon,
      props.address,
      props.city,
      props.state,
      props.country,
      props.email,
      props.phone
    );
  }
}
