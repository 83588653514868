import { createBrowserRouter } from "react-router-dom";
import { Home } from "../../Home";
import Layout from "../../Layout";
import { LoginPage } from "../login/LoginPage";
import { ObituariesPage } from "../obituariesAdmin/ObituariesPage";
import { ObituaryEditPage } from "../obituariesAdmin/ObituaryEditPage";
import { FallbackError } from "./FallbackError";
import { ProtectedRoute } from "./ProtectedRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home/:ticker",
    errorElement: <FallbackError />,
    element: (
      <ProtectedRoute>
        <Layout>
          <ObituariesPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/home/:ticker/obituaries/new",
    errorElement: <FallbackError />,
    element: (
      <ProtectedRoute>
        <Layout>
          <ObituaryEditPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/home/:ticker/obituaries/:obituaryId",
    errorElement: <FallbackError />,
    element: (
      <ProtectedRoute>
        <Layout>
          <ObituaryEditPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);
