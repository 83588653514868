import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IconButton, Switch, Typography } from "@material-tailwind/react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Obituary } from "../../../modules/obituaries/domain/Obituary";
import { format } from "../../../modules/shared/utils/date";
import { useCallback } from "react";

export interface ObituariesPageProps {
  className?: string;
  obituaries: Obituary[];
  propertyTicker: string;
  onToggleActive?: (obituary: Obituary, active: boolean) => void;
  onDelete?: (obituary: Obituary) => void;
}

const TABLE_HEAD = [
  "Nombre",
  "Lugar defunción",
  "Fecha defunción",
  "Activa",
  "",
];

export function ObituariesTable({
  propertyTicker,
  obituaries,
  className,
  onToggleActive,
  onDelete,
}: ObituariesPageProps) {
  const handleToggleActive = useCallback(
    (obituary: Obituary, active: boolean) => {
      if (onToggleActive) onToggleActive(obituary, active);
    },
    [onToggleActive]
  );

  const handleDelete = useCallback(
    (obituary: Obituary) => {
      if (onDelete) onDelete(obituary);
    },
    [onDelete]
  );

  return (
    <div className={clsx(className, "w-full h-full")}>
      <table className="w-full">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-y border-slate-200 bg-slate-50 hover:bg-slate-100 px-2 py-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70 text-start"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {obituaries.map((obituary, index) => {
            const isLast = index === obituaries.length - 1;
            const classes = isLast
              ? "h-6 p-2"
              : "h-6 p-2 border-b border-blue-gray-50";

            return (
              <tr key={obituary.id}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {obituary.name}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {obituary.deceasedPlace}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {format(obituary.deceasedDate, "dd/MM/yyyy")}
                  </Typography>
                </td>
                <td className={classes}>
                  <Switch
                    defaultChecked={obituary.active}
                    onChange={(e) =>
                      handleToggleActive(obituary, e.target.checked)
                    }
                  />
                </td>
                <td className={classes}>
                  <div className="flex gap-4">
                    <Link
                      to={`/home/${propertyTicker}/obituaries/${obituary.id}/`}
                    >
                      <IconButton
                        className="rounded-full"
                        variant="text"
                        size="sm"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </IconButton>
                    </Link>
                    <IconButton
                      className="rounded-full"
                      variant="text"
                      size="sm"
                      onClick={() => handleDelete(obituary)}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </IconButton>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
