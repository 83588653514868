import { UserProperty, UserPropertyProps } from "./UserProperty";

export interface UserProps {
  id: string;
  email: string;
  name: string;
  properties: UserPropertyProps[];
}

export class User {
  public readonly id: string;
  public readonly email: string;
  public readonly name: string;
  public readonly properties: UserProperty[];

  constructor(props: UserProps) {
    this.id = props.id;
    this.email = props.email;
    this.name = props.name;
    this.properties = props.properties.map(UserProperty.of);
  }

  static of(props: UserProps) {
    return new User(props);
  }
}
