import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../login/useAuth";

export function ProtectedRoute({ children }: PropsWithChildren) {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
