import { Property } from "../../property/domain/Property";
import { PropertyGatewayHttp } from "../../property/infrasctructure/PropertyGateway.http";
import { Obituary } from "../domain/Obituary";
import { ObituaryRepositorySupabase } from "../infrastructure/ObituaryRepository.supabase";

interface ObituaryImages {
  profileImage: File;
  obituaryImage: File;
}

const obituaryRepository = new ObituaryRepositorySupabase();
const propertyGateway = new PropertyGatewayHttp();

export const createObituary = async (
  property: Property,
  obituary: Obituary,
  { profileImage, obituaryImage }: ObituaryImages
): Promise<Obituary> => {
  const [profileImageUrl, obituaryImageUrl] = await Promise.all([
    obituaryRepository.saveObituaryProfileImage(
      property.ticker,
      obituary.id,
      profileImage as File
    ),
    obituaryRepository.saveObituaryImage(
      property.ticker,
      obituary.id,
      obituaryImage as File
    ),
  ]);

  const updatedObituary = Obituary.of({
    ...obituary,
    profileImage: profileImageUrl,
    obituaryImage: obituaryImageUrl,
  });

  await obituaryRepository.save(property.id, updatedObituary);
  await propertyGateway.revalidate(property.ticker);

  return updatedObituary;
};
