import { PropertyGateway } from "../gateway/Property.gateway";

export class PropertyGatewayHttp implements PropertyGateway {
  async revalidate(ticker: string): Promise<void> {
    const url = import.meta.env.VITE_OBITUARIES_BASE_URL;
    await fetch(`${url}/api/home/${ticker}/revalidate`);
  }

  async revalidateObituary(ticker: string, obituaryId: string): Promise<void> {
    const url = import.meta.env.VITE_OBITUARIES_BASE_URL;
    await fetch(
      `${url}/api/home/${ticker}/obituaries/${obituaryId}/revalidate`
    );
  }
}
