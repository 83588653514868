const theme = {
  button: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "primary",
    },
    valid: {
      variants: ["filled", "outlined", "text"],
      sizes: ["sm", "md", "lg"],
      colors: ["primary"],
    },
    styles: {
      variants: {
        filled: {
          primary: {
            backgroud: "bg-primary",
            color: "text-white",
          },
        },
        outlined: {
          primary: {
            border: "border border-primary",
            color: "text-primary",
          },
        },
        text: {
          primary: {
            color: "text-primary",
            hover: "hover:bg-blue-gray-500/10",
            active: "active:bg-blue-gray-500/30",
          },
        },
      },
    },
  },
};

export { theme };
