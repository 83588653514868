import { clsx } from "clsx";
import { PropsWithChildren } from "react";

export interface FieldProps extends PropsWithChildren {
  error?: string;
  className?: string;
}

export function Field({ error, children, className }: FieldProps) {
  return (
    <div className={clsx("w-full", className)}>
      {children}
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </div>
  );
}
