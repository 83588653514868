import { Property } from "../../property/domain/Property";
import { PropertyGatewayHttp } from "../../property/infrasctructure/PropertyGateway.http";
import { Obituary } from "../domain/Obituary";
import { ObituaryRepositorySupabase } from "../infrastructure/ObituaryRepository.supabase";

const obituaryRepository = new ObituaryRepositorySupabase();
const propertyGateway = new PropertyGatewayHttp();

export const deleteObituary = async (
  property: Property,
  obituary: Obituary
): Promise<void> => {
  await obituaryRepository.delete(obituary.id);
  await propertyGateway.revalidate(property.ticker);
};
