import { parse } from "../../shared/utils/date";

export interface ObituaryCreateProps {
  name: string;
  deceasedDate: Date | string; // yyyy-MM-dd
  deceasedPlace: string;
  age: number;
  notificationEmails: string[];
  active: boolean;
}

export interface ObituaryProps extends ObituaryCreateProps {
  id: string;
  profileImage: string;
  obituaryImage: string;
  state: ObituaryState;
}

export enum ObituaryState {
  LIVE = "live",
  DELETED = "deleted",
}

export class Obituary {
  public readonly id: string;
  public readonly name: string;
  public readonly deceasedDate: Date;
  public readonly deceasedPlace: string;
  public readonly age: number;
  public readonly notificationEmails: string[];
  public readonly profileImage: string;
  public readonly obituaryImage: string;
  public readonly active: boolean;
  public readonly state: ObituaryState;

  private constructor(props: ObituaryProps) {
    this.id = props.id;
    this.name = props.name;
    this.deceasedDate =
      typeof props.deceasedDate === "string"
        ? parse(props.deceasedDate, "yyyy-MM-dd")
        : props.deceasedDate;
    this.deceasedPlace = props.deceasedPlace;
    this.age = props.age;
    this.notificationEmails = props.notificationEmails ?? [];
    this.profileImage = props.profileImage;
    this.obituaryImage = props.obituaryImage;
    this.active = props.active;
    this.state = props.state;
  }

  clone(props: Partial<ObituaryProps>) {
    return new Obituary({
      ...this,
      ...props,
    });
  }

  static create(props: ObituaryCreateProps) {
    return new Obituary({
      id: crypto.randomUUID(),
      name: props.name,
      deceasedDate: props.deceasedDate,
      deceasedPlace: props.deceasedPlace,
      age: props.age,
      notificationEmails: props.notificationEmails,
      active: props.active,
      profileImage: "none",
      obituaryImage: "none",
      state: ObituaryState.LIVE,
    });
  }

  static of(props: ObituaryProps) {
    return new Obituary(props);
  }
}
