import useSWR from "swr";
import { PropertyRepositorySupabase } from "../../../modules/property/infrasctructure/PropertyRepository.supabase";
import { UnAuthorizedException } from "../../../modules/shared/exceptions";

export const useProperty = (ticker: string) => {
  const propertyRepository = new PropertyRepositorySupabase();
  const {
    data: property,
    error,
    isLoading,
  } = useSWR(`/properties/${ticker}`, async () =>
    propertyRepository.findByTicker(ticker)
  );

  if (error instanceof UnAuthorizedException) {
    throw error;
  }

  return {
    property,
    error,
    isLoading,
  };
};
