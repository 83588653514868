import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../login/useAuth";

export const useRedirectToProperty = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const _navigate = useCallback(() => {
    if (!user) {
      return navigate("/login");
    }

    return navigate(`/home/${user.properties[0].ticker}`);
  }, [navigate, user]);

  return {
    navigateToProperty: _navigate,
  };
};
