import { Property } from "../../property/domain/Property";
import { PropertyGatewayHttp } from "../../property/infrasctructure/PropertyGateway.http";
import { Obituary } from "../domain/Obituary";
import { ObituaryRepositorySupabase } from "../infrastructure/ObituaryRepository.supabase";

const obituaryRepository = new ObituaryRepositorySupabase();
const propertyGateway = new PropertyGatewayHttp();

export const updateObituaryActive = async (
  property: Property,
  obituary: Obituary,
  active: boolean
): Promise<void> => {
  await obituaryRepository.updateActive(obituary.id, active);
  await propertyGateway.revalidate(property.ticker);
};
