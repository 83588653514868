import { Property } from "../../property/domain/Property";
import { PropertyGatewayHttp } from "../../property/infrasctructure/PropertyGateway.http";
import { Obituary } from "../domain/Obituary";
import { ObituaryRepositorySupabase } from "../infrastructure/ObituaryRepository.supabase";

interface ObituaryImages {
  profileImage: File;
  obituaryImage: File;
}

const obituaryRepository = new ObituaryRepositorySupabase();
const propertyGateway = new PropertyGatewayHttp();

export const updateObituary = async (
  property: Property,
  obituary: Obituary,
  images?: Partial<ObituaryImages>
): Promise<Obituary> => {
  let profileImageUrl: string | undefined = undefined;
  let obituaryImageUrl: string | undefined = undefined;

  if (images && images.profileImage) {
    profileImageUrl = await obituaryRepository.saveObituaryProfileImage(
      property.ticker,
      obituary.id,
      images.profileImage
    );
  }

  if (images && images.obituaryImage) {
    obituaryImageUrl = await obituaryRepository.saveObituaryImage(
      property.ticker,
      obituary.id,
      images.obituaryImage
    );
  }

  const updatedObituary = Obituary.of({
    ...obituary,
    profileImage: profileImageUrl ?? obituary.profileImage,
    obituaryImage: obituaryImageUrl ?? obituary.obituaryImage,
  });

  await obituaryRepository.update(updatedObituary);
  await propertyGateway.revalidateObituary(property.ticker, obituary.id);

  return updatedObituary;
};
