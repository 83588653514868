import { Button } from "@material-tailwind/react";
import { useAuth } from "../../login/useAuth";
import { PropsWithChildren } from "react";
import logo from "../../../assets/logo-navbar.png";

export function Navbar({ children }: PropsWithChildren) {
  const { user, logout } = useAuth();

  if (!user) {
    return;
  }

  return (
    <div className="h-16 p-4 bg-secondary flex justify-between items-center">
      <div className="flex gap-4 items-center">
        <img src={logo} className="h-12" />
        {children}
      </div>
      <div className="flex gap-4 items-center">
        <span>{user.name}</span>
        <Button variant="text" onClick={logout}>
          Cerrar sesión
        </Button>
      </div>
    </div>
  );
}
