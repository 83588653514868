import { PropsWithChildren, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Navbar } from "./sections/shared/components/Navbar";
import { useProperty } from "./sections/shared/hooks/useProperty";

export default function Layout({ children }: PropsWithChildren) {
  const { ticker } = useParams() as { ticker: string };
  const { property, error, isLoading } = useProperty(ticker);
  if (isLoading || !property) {
    return <span>Cargando...</span>;
  }

  if (error) {
    console.error("Error loading property", error);
    throw new Error(`Error loading property [${ticker}]`);
  }

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col p-4 gap-4 flex-1">
        <span className="text-xl">
          Bienvenido a<span className="italic font-bold"> {property.name}</span>
        </span>
        <div className="flex-1">
          <Suspense fallback={<span>Cargando...</span>}>{children}</Suspense>
        </div>
      </div>
    </div>
  );
}
