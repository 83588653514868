import {
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { forwardRef, useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";

export interface DatePickerProps {
  label?: string;
  value?: Date;
  onChange?: (value: Date) => void;
  required?: boolean;
  error?: boolean;
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    { label, value: defaultValue, onChange, required, error }: DatePickerProps,
    ref
  ) => {
    const [value, setValue] = useState<Date | undefined>(defaultValue);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setValue(defaultValue);
      if (inputRef.current) {
        // inputRef.current.focus();
        inputRef.current.value = defaultValue
          ? format(defaultValue, "PPP")
          : "";
      }
    }, [defaultValue]);

    return (
      <div>
        <Popover placement="bottom">
          <PopoverHandler>
            <Input
              label={label}
              required={required}
              error={error}
              ref={ref}
              inputRef={inputRef}
              value={value ? format(value, "PPP") : ""}
              onChange={() => {
                console.log("eyyy");
              }}
            />
          </PopoverHandler>
          <PopoverContent>
            <DayPicker
              mode="single"
              selected={value}
              onSelect={(value) => {
                setValue(value);
                if (inputRef.current) {
                  inputRef.current.value = format(value, "PPP");
                  inputRef.current.focus();
                }

                if (onChange) {
                  onChange(value);
                }
              }}
              classNames={{
                caption: "flex justify-center py-2 mb-4 relative items-center",
                caption_label: "text-sm font-medium text-gray-900",
                table: "w-full border-collapse",
                head_row: "flex font-medium text-gray-900",
                head_cell: "m-0.5 w-9 font-normal text-sm",
                row: "flex w-full mt-2",
                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                day: "h-9 w-9 p-0 font-normal",
                day_range_end: "day-range-end",
                day_selected:
                  "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                day_today: "rounded-md bg-gray-200 text-gray-900",
                day_outside:
                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                day_disabled: "text-gray-500 opacity-50",
                day_hidden: "invisible",
              }}
              required
            />
          </PopoverContent>
        </Popover>
      </div>
    );
  }
);
