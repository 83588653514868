export class Page<T> {
  constructor(
    public readonly items: T[],
    public readonly total: number,
    public readonly page: number,
    public readonly pageSize: number
  ) {}

  static of<T>(items: T[], total: number, page: number, pageSize: number) {
    return new Page<T>(items, total, page, pageSize);
  }
}
