import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { forwardRef, PropsWithChildren, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface DropUploadProps extends PropsWithChildren {
  label?: string;
  className?: string;
  error?: boolean;
  onDrop?: (acceptedFiles: File[]) => void;
}

export const DropUpload = forwardRef<HTMLInputElement, DropUploadProps>(
  ({ className, label, error, onDrop, children }: DropUploadProps, ref) => {
    const onDropHandler = useCallback(
      (acceptedFiles: File[]) => {
        if (onDrop) {
          onDrop(acceptedFiles);
        }
      },
      [onDrop]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: onDropHandler,
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "image/webp": [".webp"],
      },
    });

    return (
      <div
        className={clsx(
          "border border-blue-gray-200 p-4 rounded-[7px] relative flex items-center justify-center",
          label && "border-t-0",
          error && "border-red-500",

          className
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} ref={ref} />
        {label && (
          <label
            className={clsx(
              "flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900",
              error && "text-red-500 after:border-red-500 before:border-red-500"
            )}
          >
            {label}
          </label>
        )}
        {children ? children : <ArrowUpTrayIcon className="w-5 h-5" />}
      </div>
    );
  }
);
