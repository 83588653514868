import { supabase } from "../../shared/infrastructure/supabase";
import { User } from "../domain/User";
import { UserRepository } from "../domain/UserRepository";

export class UserRepositorySupabase implements UserRepository {
  async findById(id: string): Promise<User | undefined> {
    const { data, error } = await supabase
      .from("users")
      .select("*, properties(id, ticker)")
      .eq("id", id)
      .single();

    if (error) {
      return undefined;
    }

    const { data: supabaseUser } = await supabase.auth.getUser();
    if (!supabaseUser) {
      console.error("supabase user not found");
      return undefined;
    }

    return User.of({
      id: data.id,
      email: supabaseUser.user?.email as string,
      name: data.name,
      properties: data.properties,
    });
  }
}
