import { useLocalStorage } from "@uidotdev/usehooks";
import { createContext, PropsWithChildren, useCallback, useMemo } from "react";
import { User } from "../../modules/auth/domain/User";
import { supabase } from "../../modules/shared/infrastructure/supabase";
import { UserRepositorySupabase } from "../../modules/auth/infrastructure/UserRepository.supabase";

interface IAuthContext {
  login: (username: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  user?: User;
  token?: string;
}

export const AuthContext = createContext<IAuthContext>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: async (_username: string, _password: string) => {
    throw new Error("Not implemented");
  },
  logout: async () => {
    throw new Error("Not implemented");
  },
  user: undefined,
  token: undefined,
});

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useLocalStorage<User | undefined>("user", undefined);
  const [token, setToken] = useLocalStorage<string | undefined>(
    "token",
    undefined
  );

  const userRepository = useMemo(() => new UserRepositorySupabase(), []);

  const login = useCallback(
    async (username: string, password: string) => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: username,
        password,
      });

      if (error) {
        throw error;
      }

      console.debug("User logged!", data);
      const user = await userRepository.findById(data.user.id);
      if (!user) {
        throw new Error("User not found");
      }

      setUser(user);
      setToken(data.session?.access_token);
      return user;
    },
    [setToken, setUser, userRepository]
  );

  const logout = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      throw error;
    }

    setUser(undefined);
    setToken(undefined);
  }, [setToken, setUser]);

  const value = useMemo(
    () => ({ user, login, logout, token }),
    [login, logout, user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
