import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, IconButtonProps } from "@material-tailwind/react";
import { RefAttributes } from "react";

export interface PaginationProps {
  total: number;
  page: number;
  size: number;
  onChange(page: number): void;
}

export function Pagination({ total, page, size, onChange }: PaginationProps) {
  const active = page + 1;
  const totalPages = Math.ceil(total / size);

  const getItemProps = (
    index: number
  ): Partial<IconButtonProps & RefAttributes<HTMLButtonElement>> => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => onChange(index),
    className: "rounded-full",
  });

  const next = () => {
    if (active === totalPages) return;
    onChange(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    onChange(active - 1);
  };

  return (
    <div className="flex items-center gap-4">
      <Button
        variant="text"
        className="flex items-center gap-2 rounded-full"
        onClick={prev}
        disabled={active === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previa
      </Button>
      <div className="flex items-center gap-2">
        {[...Array(totalPages)].map((_, index) => (
          <IconButton
            key={index}
            {...getItemProps(index + 1)}
            className="rounded-full"
          >
            {index + 1}
          </IconButton>
        ))}
      </div>
      <Button
        variant="text"
        className="flex items-center gap-2 rounded-full"
        onClick={next}
        disabled={active === totalPages}
      >
        Siguiente
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}
