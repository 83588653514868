import { UnAuthorizedException } from "../../shared/exceptions";
import { supabase } from "../../shared/infrastructure/supabase";
import { Property } from "../domain/Property";
import { PropertyRepository } from "../domain/PropertyRepository";

export class PropertyRepositorySupabase implements PropertyRepository {
  async findByTicker(ticker: string): Promise<Property | undefined> {
    const { data, error } = await supabase
      .from("properties")
      .select("*")
      .eq("ticker", ticker);

    if (error) {
      console.error("Error while fetching property", error);
      return undefined;
    }

    if (data.length === 0) {
      throw new UnAuthorizedException("Property not found");
    }

    return Property.of(data[0]);
  }
}
