export interface UserPropertyProps {
  id: string;
  ticker: string;
}

export class UserProperty {
  public readonly id: string;
  public readonly ticker: string;

  constructor(props: UserPropertyProps) {
    this.id = props.id;
    this.ticker = props.ticker;
  }

  static of(props: UserPropertyProps) {
    return new UserProperty(props);
  }
}
