import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Card, CardBody, CardFooter } from "@material-tailwind/react";
import { Link, useRouteError } from "react-router-dom";
import {
  NotFoundException,
  UnAuthorizedException,
} from "../../modules/shared/exceptions";

export function FallbackError() {
  const error = useRouteError();

  let component: React.ReactNode;
  switch (error?.constructor) {
    case UnAuthorizedException: {
      component = <UnAuthorizedError />;
      break;
    }
    case NotFoundException: {
      component = <NotFoundError />;
      break;
    }
    default: {
      component = <GenericError />;
      break;
    }
  }

  return (
    <div className="flex w-full h-full items-center bg-gray-100">
      {component}
    </div>
  );
}

function UnAuthorizedError() {
  return (
    <Card className="w-full max-w-md mx-auto">
      <CardBody className="flex flex-col gap-4 px-6 pt-6 pb-0">
        <span className="flex items-center gap-2 text-red-500">
          <ExclamationCircleIcon className="h-5 w-5" />
          Acción no autorizada
        </span>
        <p className="text-muted-foreground">
          Está accediendo intentado acceder a un sitio no autorizado.
        </p>
      </CardBody>
      <CardFooter className="flex justify-end">
        <Link to="/">
          <Button className="ml-2">Volver</Button>
        </Link>
      </CardFooter>
    </Card>
  );
}

function GenericError() {
  return (
    <Card className="w-full max-w-md mx-auto">
      <CardBody className="flex flex-col gap-4 px-6 pt-6 pb-0">
        <span className="flex items-center gap-2 text-red-500">
          <ExclamationCircleIcon className="h-5 w-5" />
          Error
        </span>
        <p className="text-muted-foreground">
          Ha ocurrido un error, por favor contacte con el administrador.
        </p>
      </CardBody>
      <CardFooter className="flex justify-end">
        <Link to="/">
          <Button className="ml-2">Volver</Button>
        </Link>
      </CardFooter>
    </Card>
  );
}

function NotFoundError() {
  return (
    <div className="flex flex-col items-center m-auto">
      <h1 className="text-4xl font-bold mb-2">404</h1>
      <h2 className="text-2xl font-semibold mb-4">Pagina no encontrada</h2>
      <p className="text-muted-foreground text-center max-w-md mb-8">
        ¡Oops! La página que estás buscando no existe
      </p>
      <Link to="/">
        <Button>Volver a inicio</Button>
      </Link>
    </div>
  );
}
