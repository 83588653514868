import { Button, Input } from "@material-tailwind/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../../assets/logo.png";
import { useRedirectToProperty } from "../shared/hooks/useRedirectToProperty";
import { useAuth } from "./useAuth";

type FormInput = {
  username: string;
  password: string;
};

export function LoginPage() {
  const [error, setError] = useState(false);

  const { login, user } = useAuth();
  const { navigateToProperty } = useRedirectToProperty();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const handleLogin = useCallback(
    async ({ username, password }: FormInput) => {
      try {
        const user = await login(username, password);
        if (user.properties.length === 0) {
          throw new Error("User doesn't have any property assigned");
        }

        navigateToProperty();
      } catch (error) {
        console.error("Error during login", error);
        setError(true);
      }
    },
    [login, navigateToProperty]
  );

  useEffect(() => {
    if (user) {
      navigateToProperty();
    }
  });

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="border p-6 border-gray-300 rounded-md w-80 flex flex-col gap-6 ">
        <img
          src={logo}
          alt="logo"
          height="128"
          width="270"
          className="h-32 object-contain"
        />
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(handleLogin)}
        >
          <div>
            <Input
              label="Email"
              type="email"
              autoComplete="username"
              error={Boolean(errors.username)}
              {...register("username", { required: true })}
            />
            {errors.username && (
              <span className="text-xs text-red-500">
                El email es requerido
              </span>
            )}
          </div>
          <div>
            <Input
              label="Contraseña"
              type="password"
              autoComplete="current-password"
              error={Boolean(errors.password)}
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span className="text-xs text-red-500">
                La contraseña es requerida
              </span>
            )}
          </div>
          {error && (
            <span className="text-sm text-red-500">Credenciales inválidas</span>
          )}
          <Button type="submit">Entrar</Button>
        </form>
      </div>
    </div>
  );
}
