import { useCallback, useState } from "react";
import useSWR from "swr";
import { ObituaryRepositorySupabase } from "../../modules/obituaries/infrastructure/ObituaryRepository.supabase";
import { Pagination } from "../shared/ui/Pagination";
import { useProperty } from "../shared/hooks/useProperty";
import { ObituariesTable } from "./components/ObituariesTable";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { updateObituaryActive } from "../../modules/obituaries/application/updateObituaryActive.useCase";
import { Obituary } from "../../modules/obituaries/domain/Obituary";
import { deleteObituary } from "../../modules/obituaries/application/deleteObituary.useCase";
import toast from "react-hot-toast";
import { Page } from "../../modules/shared/domain/Page";

const DEFAULT_PAGE_SIZE = 10;

const obituariesRepository = new ObituaryRepositorySupabase();

export function ObituariesPage() {
  const [pageRequest, setPageRequest] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);
  const [deletionObituary, setDeletionObituary] = useState<Obituary>();

  const { ticker } = useParams() as { ticker: string };
  const { property } = useProperty(ticker);

  const {
    data: obituaries,
    error,
    mutate,
  } = useSWR(
    property
      ? `/obituaries/${property.ticker}?page=${pageRequest.page}&size=${pageRequest.pageSize}`
      : null,
    () =>
      property
        ? obituariesRepository.findByProperty(property.id, pageRequest)
        : undefined,
    { suspense: true, revalidateOnFocus: false }
  );

  const handlePagination = useCallback(
    (page: number) => {
      setPageRequest({
        ...pageRequest,
        page: page - 1,
      });
    },
    [pageRequest]
  );

  const handleToggleActive = useCallback(
    async (obituary: Obituary, active: boolean) => {
      if (!property) return;
      await updateObituaryActive(property, obituary, active);
    },
    [property]
  );

  const handleDeletion = useCallback(
    async (obituary: Obituary) => {
      if (!property || !obituaries) return;
      await deleteObituary(property, obituary);
      setDeletionDialogOpen(false);
      setDeletionObituary(undefined);

      const newPage = Page.of(
        obituaries.items.filter((o) => o.id !== obituary.id),
        obituaries.total - 1,
        pageRequest.page,
        pageRequest.pageSize
      );
      mutate(newPage);
      toast.success("Esquela borrada correctamente");
    },
    [mutate, obituaries, pageRequest.page, pageRequest.pageSize, property]
  );

  const handleDialogOpen = (obituary: Obituary) => {
    setDeletionObituary(obituary);
    setDeletionDialogOpen(true);
  };

  const handleDialogToggle = () => setDeletionDialogOpen(!deletionDialogOpen);

  if (error) {
    throw new Error("Error fetching obituaries");
  }

  if (obituaries == null) {
    return <>No se han encontrado esquelas</>;
  }

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Esquelas</h1>
        <Link to={`/home/${ticker}/obituaries/new`}>
          <Button className="flex items-center gap-2">
            <PlusIcon className="h-4 w-4" />
            Nueva esquela
          </Button>
        </Link>
      </div>
      <div className="flex flex-col flex-1 gap-2 items-end">
        <ObituariesTable
          propertyTicker={ticker}
          obituaries={obituaries.items}
          onToggleActive={handleToggleActive}
          onDelete={handleDialogOpen}
        />
        {obituaries.total > DEFAULT_PAGE_SIZE && (
          <Pagination
            page={obituaries.page}
            total={obituaries.total}
            size={obituaries.pageSize}
            onChange={handlePagination}
          />
        )}
      </div>
      {deletionObituary && (
        <Dialog open={deletionDialogOpen} handler={handleDialogToggle}>
          <DialogHeader>Borrar esquela</DialogHeader>
          <DialogBody>
            El borrado de una esquela no se puede deshacer. Si desea que la
            esquela no aparezca en esquelas actuales, simplemente hay que
            desactivarla. Si desea eliminarla del sistema para siempre continue.
          </DialogBody>
          <DialogFooter className="flex justify-end gap-2">
            <Button variant="text" onClick={handleDialogToggle}>
              Cancelar
            </Button>
            <Button onClick={() => handleDeletion(deletionObituary)}>
              Confirmar
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}
