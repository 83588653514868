import { Page } from "../../shared/domain/Page";
import { PageRequest } from "../../shared/domain/PageRequest";
import { NotFoundException } from "../../shared/exceptions";
import { supabase } from "../../shared/infrastructure/supabase";
import { format } from "../../shared/utils/date";
import { Obituary, ObituaryState } from "../domain/Obituary";
import { ObituaryRepository } from "../domain/ObituaryRepository";

interface ObituaryPersistence {
  id: string;
  active: boolean;
  age: number;
  created_at: string;
  deceasedDate: string;
  deceasedPlace: string;
  name: string;
  notification_emails: string | null;
  obituary_img_url: string;
  profile_img_url: string;
  property_id: string;
  state: string;
}

const STORAGE_BASE_URL = import.meta.env.VITE_SUPABASE_STORAGE_URL;
const VALID_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "webp"];

export class ObituaryRepositorySupabase implements ObituaryRepository {
  async findById(id: string): Promise<Obituary | undefined> {
    const { data, error } = await supabase
      .from("obituaries")
      .select()
      .eq("id", id);

    if (error) {
      console.error(`Error fetching obituary with id [${id}]`, error);
      throw new Error("Error fetching obituary");
    }

    if (data.length === 0) {
      throw new NotFoundException("Obituary not found");
    }

    return Obituary.of(this.fromPersistence(data[0]));
  }

  async findByProperty(
    propertyId: string,
    pageRequest: PageRequest
  ): Promise<Page<Obituary>> {
    const { count } = await supabase
      .from("obituaries")
      .select("*", { count: "exact", head: true })
      .eq("property_id", propertyId)
      .eq("state", ObituaryState.LIVE);

    const { data, error } = await supabase
      .from("obituaries")
      .select("*")
      .eq("property_id", propertyId)
      .eq("state", ObituaryState.LIVE)
      .range(
        pageRequest.page * pageRequest.pageSize,
        pageRequest.page * pageRequest.pageSize + pageRequest.pageSize - 1
      )
      .order("deceasedDate", { ascending: false });

    if (error || count == null) {
      console.error(
        `Error fetching obituaries for property [${propertyId}]`,
        error
      );
      throw new Error("Error fetching obituaries");
    }

    const obituaries = data.map((obituary) =>
      Obituary.of(this.fromPersistence(obituary))
    );

    return Page.of(obituaries, count, pageRequest.page, pageRequest.pageSize);
  }

  async save(property: string, obituary: Obituary): Promise<void> {
    const { error } = await supabase.from("obituaries").insert({
      id: obituary.id,
      name: obituary.name,
      deceasedPlace: obituary.deceasedPlace,
      deceasedDate: format(obituary.deceasedDate, "yyyy-MM-dd"),
      age: obituary.age,
      notification_emails: obituary.notificationEmails.join(","),
      profile_img_url: obituary.profileImage,
      obituary_img_url: obituary.obituaryImage,
      active: obituary.active,
      property_id: property,
    });

    if (error) {
      console.error("Error saving obituary", error);
      throw new Error("Error saving obituary");
    }
  }

  async update(obituary: Obituary): Promise<void> {
    const { error } = await supabase
      .from("obituaries")
      .update({
        name: obituary.name,
        deceasedPlace: obituary.deceasedPlace,
        deceasedDate: format(obituary.deceasedDate, "yyyy-MM-dd"),
        age: obituary.age,
        notification_emails: obituary.notificationEmails.join(","),
        profile_img_url: obituary.profileImage,
        obituary_img_url: obituary.obituaryImage,
        active: obituary.active,
      })
      .eq("id", obituary.id);

    if (error) {
      console.error("Error updating obituary", error);
      throw new Error("Error updating obituary");
    }
  }

  async updateActive(obituaryId: string, active: boolean): Promise<void> {
    const { error } = await supabase
      .from("obituaries")
      .update({ active })
      .eq("id", obituaryId);

    if (error) {
      console.error("Error updating active obituary", error);
      throw new Error("Error updating active obituary");
    }
  }

  async delete(obituaryId: string): Promise<void> {
    const { error } = await supabase
      .from("obituaries")
      .update({ state: ObituaryState.DELETED })
      .eq("id", obituaryId);

    if (error) {
      console.error("Error updating active obituary", error);
      throw new Error("Error updating active obituary");
    }
  }

  async saveObituaryProfileImage(
    propertyTicker: string,
    obituaryId: string,
    image: File
  ): Promise<string> {
    const extension = image.name.split(".").pop() ?? "";
    if (!VALID_IMAGE_EXTENSIONS.includes(extension)) {
      throw new Error("Invalid image extension");
    }

    const time = new Date().getTime();
    const { data, error } = await supabase.storage
      .from("obituaries")
      .upload(
        `${propertyTicker}/${obituaryId}/profile-${time}.${extension}`,
        image,
        {
          upsert: true,
        }
      );

    if (error) {
      console.error("Error uploading profile image", error);
      throw new Error("Error uploading profile image");
    }

    return `${STORAGE_BASE_URL}/${data.fullPath}`;
  }

  async saveObituaryImage(
    propertyTicker: string,
    obituaryId: string,
    image: File
  ): Promise<string> {
    const extension = image.name.split(".").pop() ?? "";
    if (!VALID_IMAGE_EXTENSIONS.includes(extension)) {
      throw new Error("Invalid image extension");
    }

    const time = new Date().getTime();
    const { data, error } = await supabase.storage
      .from("obituaries")
      .upload(
        `${propertyTicker}/${obituaryId}/obituary-${time}.${extension}`,
        image,
        {
          upsert: true,
        }
      );

    if (error) {
      console.error("Error uploading obituary image", error);
      throw new Error("Error uploading obituary image");
    }

    return `${STORAGE_BASE_URL}/${data.fullPath}`;
  }

  private fromPersistence(obituary: ObituaryPersistence): Obituary {
    return Obituary.of({
      id: obituary.id,
      name: obituary.name,
      deceasedDate: obituary.deceasedDate,
      deceasedPlace: obituary.deceasedPlace,
      age: obituary.age,
      notificationEmails: obituary.notification_emails?.split(",") ?? [],
      profileImage: obituary.profile_img_url,
      obituaryImage: obituary.obituary_img_url,
      active: obituary.active,
      state: obituary.state as ObituaryState,
    });
  }
}
