import { useEffect } from "react";
import { useRedirectToProperty } from "./sections/shared/hooks/useRedirectToProperty";

export function Home() {
  const { navigateToProperty } = useRedirectToProperty();

  useEffect(() => {
    navigateToProperty();
  }, [navigateToProperty]);

  return <>Home</>;
}
